import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import './404.scss';

const NotFoundPage = () => (
  <Layout extra={false}>
    <SEO title="404: Not found" />
    <div className="Page-404">
      <h1 className="DisplayBlackCenter">NOT FOUND</h1>
      <p className="Page-404__Des">You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
